.MenuCard{
    display: flex;
    flex-direction: column;
    background-color: rgba(33, 34, 41, 0.8);
    color: wheat;
    height: 100%;
    border-radius: 15px;
    gap: 8px;
}
.MenuCard-Img{
    width: 100%;
    border-top-left-radius: 15px ; border-top-right-radius: 15px;
}

.ItemDetail{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 15px 10px 15px;
  gap: 6px;
}
.ItemDetail-Description{
  text-align: start;
  padding: 0px 8px 0px 8px;
}
.ItemDetail-InShoppingCart{
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0px 8px 0px 8px;
}


.PlusAmountMinus{
    position: static;
    display: flex;
    align-items: baseline;
    width: 90px;
    /* margin: 10px; */
  }
  .plus{
    position: relative;
    display: flex;
    width: 30px; height: 30px;
    justify-content: center;
    /* align-items: center; */
    left: 5%;
    /* z-index: 0; */
  }
  .plusCircle{
    position: absolute;
    height: 30px; width: 30px;
  }
  .plusText{
    position: absolute;
    color: lightgreen;
    /* bottom: 5%; */
  }
  .amount{
    display: flex;
    position: relative;
    width: 52px;
    height: 52px;
    /* background-color: rgb(148, 132, 111); */
    justify-content: center;
    align-items: center;
  }
  .amountCircle{
  position: absolute;
  
  }
  .amountText{
    position: absolute;
    color:hsla(34, 16%, 17%, 1);
    /* bottom: 6px; */
  }
  .minus{
    position: relative;
    display: flex;
    width: 30px; height: 30px;
    justify-content: center;
    align-items: center;
    right: 5%;
    /* z-index: 0; */
  }
  .minusCircle{
    position: absolute;
    height: 30px; width: 30px;
  }
  .minusText{
    position: absolute;
    color: tomato;
  }