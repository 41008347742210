
.GSushiMenu{
    display: grid;
    grid-template-columns: repeat(3, 32%);
    justify-content: space-around;
    padding: 30px 40px;
    gap: 10px;
}

@media screen and (max-width: 1100px){
    .GSushiMenu{
      grid-template-columns: repeat(2, 45%);
  
    }
  }
  @media screen and (max-width: 600px) {
    .GSushiMenu{
      grid-template-columns: repeat(1, 60%);
  
    }
  }
  @media screen and (max-width: 390px) {
    .GSushiMenu {
      grid-template-columns: repeat(1, 99%);
  
    }
  }