@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css");

.NavBar > a:link{
    display: contents;
}


.NavBar{
    position: sticky;
    top: 0;
    /* overflow: hidden; */
    height:fit-content;
    padding: 0px 60px 0px 60px;
    color: hsl(39, 80%, 90%);
    background-color:#21222a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    z-index: 3;
}
.NavBar-logo{
    height: 10vh;
    padding-top: 2vh;
}
.NavBar-title{
    padding: 10px;
    margin-right: auto;
}
.NavBar-shoppingcart{
    position: relative;
}
.NavBar-shoppingcart > i{
    font-size: 2.75em;
}
.NavBar-shoppingcart-bedge {
    min-width: 1.5em;
    position: absolute;
    top: -2px; right: -2px;
    border-radius: 45%;
    background-color: tomato;
    padding: 3px;
}
